























import Vue from 'vue';
import Scene from '../../3d/scene';
import Store from '../../store';
import { DesignItem } from '../../types/Design';

let scene: Scene;

export default Vue.extend({
  props: {
    guid: String
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      value = value.toString().toLowerCase();
      return value
        .split(' ')
        .map(x => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ');
    }
  },
  data: () => ({
    design: undefined as DesignItem | undefined
  }),
  async mounted() {
    scene = new Scene('.webgl-container');

    scene.render();

    this.design = (await Store.getDesign(this.$props.guid)) as DesignItem;

    const offset = {
      x: 0,
      y: 0,
      z: 0
    };

    // Temp hardcoding basement offset
    if (this.$props.guid === '0712b493-584d-48e2-9e35-1910ba6efc3a') {
      offset.y = -0.72;
    }

    scene.loadGLB(this.design.gltf_aws_keys.External, offset);
  }
});
