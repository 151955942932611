import _Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { AxiosError } from 'axios';
import _ from 'lodash';

/**
 * Parses axios error response and sets vee-validate errors
 * from Laravel's validation error bag
 */
export default {
  install: (Vue: typeof _Vue) => {
    Vue.prototype.$setValidationErrors = (
      $validationObserver: InstanceType<typeof ValidationObserver>,
      error: AxiosError
    ) => {
      if (error.response) {
        const errorResponse = error.response;
        if (_.has(errorResponse.data, 'errors')) {
          $validationObserver.setErrors(errorResponse.data.errors);
        }
      }
    };
  }
};
