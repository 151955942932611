import { RouteConfig } from 'vue-router/types/router';
import Home from '../views/Home/Index.vue';

export default [
  {
    path: '/realtor/:guid',
    name: 'Realtor Design',
    component: Home,
    props: route => Object.assign({}, route.query, route.params)
  }
] as Array<RouteConfig>;
