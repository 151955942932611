export {};
declare global {
  interface Array<T> {
    move(from: number, to: number): void;
    insert(index: number, item: any): void;
  }
}
if (!Array.prototype.move) {
  Array.prototype.move = function(from: number, to: number) {
    this.splice(to, 0, this.splice(from, 1)[0]);
  };
}

if (!Array.prototype.insert) {
  Array.prototype.insert = function(index: number, item: any) {
    this.splice(index, 0, item);
  };
}
