import HTTP from '../service/http';
import { DesignItem } from '../types/Design';

const fetchRequests = {} as any;
export class StoreType {
  designs = {} as any;
  guidQueue = {
    guids: [] as any[],
    timeout: undefined as any,
    startAt: Date.now()
  };
  production = true;
  prodAPIKey = 'OfCaaNm6w07HvU0dyDy4r9RdZPOE5EbO2gYOajXK';
  devAPIKey = 'yre1YphIj43aQvCx87SLI2Z6Z6O9rwHU1hRJpRdJ';

  apiHost = () =>
    this.production
      ? 'https://api.archistar.ai/v1/communities-new/api/v1/sdk'
      : 'https://staging-api.archistar.io/communities/api/v1/sdk';

  async fetchDesign(guid): Promise<DesignItem | DesignItem[] | undefined> {
    try {
      // Array Guids
      // ----
      if (Array.isArray(guid)) {
        // Unexpired existing data guids
        const prefetchedGuids = guid.filter(g => this.designs[g]?.expiry > Date.now());

        // Expired or non existing data
        const unfetchedGuids = guid.filter(g => !this.designs[g] || this.designs[g]?.expiry < Date.now());

        unfetchedGuids.sort();

        // Exising data
        const prefetchedDesigns = prefetchedGuids.map(g => this.designs[g].data);

        let unfetchedDesigns = [] as DesignItem[];

        // If there's any expired or non existing data
        // retrieve it from API
        if (unfetchedGuids?.length > 0) {
          if (!fetchRequests[JSON.stringify(unfetchedGuids)]) {
            fetchRequests[JSON.stringify(unfetchedGuids)] = HTTP.post(
              'https://api.archistar.ai/v1/homes/api/v1/sdk/designs/list/',
              {
                list: unfetchedGuids,
                with_img_link: true,
                resolve_links: true
              },
              {
                'x-api-key': this.prodAPIKey
              }
            );
          }

          unfetchedDesigns = (await fetchRequests[JSON.stringify(unfetchedGuids)]).data;

          delete fetchRequests[JSON.stringify(unfetchedGuids)];
        }

        // Setting data to expire after 20 minutes
        const expiry = new Date();
        expiry.setMinutes(expiry.getMinutes() + 20);

        unfetchedDesigns.forEach(design => {
          this.designs[design.guid] = {
            data: design,
            expiry
          };
        });

        return [...prefetchedDesigns, ...unfetchedDesigns];
      }

      // Single Guid
      // ----
      if (this.designs[guid]?.expiry > Date.now()) {
        // Return existing data if it's not expired
        return this.designs[guid].data;
      }

      if (!fetchRequests[guid]) {
        fetchRequests[guid] = HTTP.post(
          'https://api.archistar.ai/v1/homes/api/v1/sdk/designs/list/',
          {
            list: [guid],
            with_img_link: true,
            resolve_links: true
          },
          {
            'x-api-key': this.prodAPIKey
          }
        );
      }

      const [design] = (await fetchRequests[guid]).data;

      delete fetchRequests[guid];

      // Setting data to expire after 20 minutes
      const expiry = new Date();
      expiry.setMinutes(expiry.getMinutes() + 20);

      this.designs[guid] = {
        data: design,
        expiry
      };

      return this.designs[guid].data;
    } catch (error) {
      console.error(
        new Error(`
          Error trying to fetch design ${guid}
          \n\t${error.message}
        `)
      );
      return undefined;
    }
  }

  /**
   * Retrieve design, smartly debounces and limits request to fewer APIs
   */
  getDesign(guid: string | number | any[], original = true) {
    const debounceMs = 200;
    const recurseMs = Date.now() - this.guidQueue.startAt;

    return new Promise(async resolve => {
      // Add guids to queue and auto removes duplicates with Set
      this.guidQueue.guids = Array.from(new Set([...this.guidQueue.guids, ...(Array.isArray(guid) ? guid : [guid])]));

      // Original call resets start time
      // Avoid resetting on recursive call
      if (original) this.guidQueue.startAt = Date.now();

      setTimeout(
        async () => {
          // If another getDesign request was made, recurse, wait on new debounced time
          if (Date.now() - this.guidQueue.startAt < debounceMs) {
            // 'False' indicating recursive behavior, to wait allow waiting on the latest call
            await this.getDesign(this.guidQueue.guids, false);
          }

          if (original) {
            const data = (await this.fetchDesign(this.guidQueue.guids)) as any[];

            if (Array.isArray(data) && !Array.isArray(guid)) {
              // Single guid request
              const result = data.find(design => design.guid === guid);

              resolve(result);
            } else if (Array.isArray(data) && Array.isArray(guid)) {
              // Array guid request
              const result = data.filter(design => guid.indexOf(design.guid) > -1);

              resolve(result);
            }
            resolve(data);
          } else {
            // Continue recursive check
            resolve(1);
          }
        },
        original ? debounceMs : recurseMs
      );
    });
  }
}

export default new StoreType();
