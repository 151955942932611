/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location): any {
  return originalPush.call(this, location);
};

Vue.use(VueRouter);

/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context('.', false, /\.routes\.ts$/);

// @ts-ignore
const routes: Array<RouteConfig> = requireModule.keys().reduce((prev, filename) => {
  const data = requireModule(filename).default || requireModule(filename);
  return [...prev, ...data];
}, [] as Array<RouteConfig>);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

export default router;
