










import Vue from 'vue';
// import { Render } from '@/3d/scene';

export default Vue.extend({
  name: 'App',
  mounted() {
    // Render('.webgl-container');
  }
});
