async function convertToJson(res: any) {
  try {
    return await res.clone().json();
  } catch (e) {
    return 'No Data';
  }
}

const existingRequests = {};

export default {
  // Http Get Request
  async get(url: RequestInfo, headers?: any) {
    if (!existingRequests[`GET${url}`]) {
      existingRequests[`GET${url}`] = fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers
        },
        method: 'get'
      });
    }

    const response = await existingRequests[`GET${url}`];

    const json = await convertToJson(response);

    delete existingRequests[`GET${url}`];

    if (!response.ok) {
      const error = {
        statusText: `${response.status} ${response.statusText}`,
        message: json
      };
      throw error;
    }

    return json;
  },

  // HTTP Post
  async post(url: RequestInfo, data: any, headers?) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        ...headers
      },
      body: JSON.stringify(data)
    });

    const json = await convertToJson(response);

    if (!response.ok) {
      const error = {
        statusText: `${response.status} ${response.statusText}`,
        message: json
      };
      throw error;
    }

    return json;
  },

  // HTTP PUT
  async put(url: RequestInfo, data: any) {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const json = await convertToJson(response);

    if (!response.ok) {
      const error = {
        statusText: `${response.status} ${response.statusText}`,
        message: json
      };
      throw error;
    }

    return json;
  },

  // HTTP Delete
  async delete(url: RequestInfo) {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
    });

    const json = await convertToJson(response);

    if (!response.ok) {
      const error = {
        statusText: `${response.status} ${response.statusText}`,
        message: json
      };
      throw error;
    }

    return 'Delete successful';
  }
};
