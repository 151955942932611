<template>
  <div class="text-4xl flex h-full w-full justify-center items-center text-gray-400 text-center">
    <span class="max-w-screen-md" v-if="errorCode === '404'">
      <div class="pb-4">Page Not Found</div>
      <span class="text-xl leading-none">
        The URL you're after no longer exists or could be mistyped
      </span>
    </span>
    <span class="max-w-screen-md" v-if="errorCode === '403'">
      <div class="pb-4">No Access</div>
      <span class="text-xl leading-none">
        It seems you don't have access to this resource
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: String
    }
  }
};
</script>

<style></style>
