/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { setupScene } from './setup';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';

export default class Scene {
  setup = undefined as ReturnType<typeof setupScene> | undefined;
  canvasContainer: string;

  constructor(canvasContainer) {
    this.canvasContainer = canvasContainer;
  }

  render() {
    const canvas = document.querySelector(this.canvasContainer) as HTMLCanvasElement;
    if (canvas) {
      this.setup = setupScene(canvas);

      const animate = () => {
        this.setup?.controls.update();
        this.setup?.render();

        // Recursively loop
        requestAnimationFrame(animate);
      };

      animate();
    } else {
      console.error('No canvas found');
    }
  }

  loadGLB(url, offset?) {
    const gltfLoader = new GLTFLoader();

    gltfLoader.load(url, gltf => {
      // 3D bounding box
      const bboxGLTF = new THREE.Box3().setFromObject(gltf.scene);

      // Desired Scene bounding box
      const bboxScene = new THREE.Vector3(4, 4, 4);

      let sizeGLTF = bboxGLTF.getSize(new THREE.Vector3());
      let centerGLTF = bboxGLTF.getCenter(new THREE.Vector3());

      // Ratio between gltf and scene
      const ratio = sizeGLTF.divide(bboxScene);

      // Adjusting scale to fit the scene
      gltf.scene.scale.x = gltf.scene.scale.x * (1 / ratio.x);
      gltf.scene.scale.y = gltf.scene.scale.y * (1 / ratio.x);
      gltf.scene.scale.z = gltf.scene.scale.z * (1 / ratio.x);

      // Recalculating new size based on new scale
      sizeGLTF = new THREE.Box3().setFromObject(gltf.scene).getSize(new THREE.Vector3());
      centerGLTF = new THREE.Box3().setFromObject(gltf.scene).getCenter(new THREE.Vector3());

      // Centering the model
      gltf.scene.position.x = gltf.scene.position.x - centerGLTF.x;
      gltf.scene.position.z = gltf.scene.position.z - centerGLTF.z;
      gltf.scene.position.y = gltf.scene.position.y - (centerGLTF.y - sizeGLTF.y / 2);

      if (offset) {
        gltf.scene.position.x += offset.x;
        gltf.scene.position.y += offset.y;
        gltf.scene.position.z += offset.z;
      }

      centerGLTF = new THREE.Box3().setFromObject(gltf.scene).getCenter(new THREE.Vector3());

      const geometry = new THREE.BoxGeometry(sizeGLTF.x * 1.3, 0.03, sizeGLTF.z * 1.2);

      const material = {
        front: new THREE.MeshBasicMaterial({
          color: '#ffffff',
          opacity: 1
        }),
        back: new THREE.MeshBasicMaterial({
          color: '#ffffff',
          opacity: 1
        }),
        side: new THREE.MeshBasicMaterial({
          color: '#afafaf',
          opacity: 1
        })
      };

      const mesh = new THREE.Mesh(geometry, [
        material.front,
        material.front,
        material.side,
        material.side,
        material.back,
        material.back
      ]);

      this.setup?.scene.add(mesh);
      this.setup?.scene.add(gltf.scene);
      (window as any).setup = this.setup;
      this.setup?.controls.target.set(0, 0, 0);
    });
  }
}
