import * as THREE from 'three';

const lights = [
  {
    light: THREE.HemisphereLight,
    color: 0xffffff,
    groundColor: 0x575757,
    intensity: 2
  },
  {
    light: THREE.SpotLight,
    color: 0xffffff,
    intensity: 2,
    position: {
      x: -50,
      y: 50,
      z: 50
    },
    castShadow: true,
    shadowBias: -0.0001,
    shadowMapSizeWidth: 1024 * 4,
    shadowMapSizeHeight: 1024 * 4
  },
  {
    light: THREE.SpotLight,
    color: 0xffffff,
    intensity: 1,
    position: {
      x: 50,
      y: 50,
      z: -50
    },
    castShadow: true,
    shadowBias: -0.0001,
    shadowMapSizeWidth: 1024 * 4,
    shadowMapSizeHeight: 1024 * 4
  }
];

export const setLights = scene => {
  lights.forEach(element => {
    let light;

    // Light types
    if (element.groundColor) {
      // Hemisphere light
      light = new element.light(element.color, element.groundColor, element.intensity);
    } else {
      // Directional and other lights
      light = new element.light(element.color, element.intensity);
    }

    // Position
    if (element.position) {
      light.position.set(element.position.x, element.position.y, element.position.z);
    }

    // Shadows
    if (element.castShadow) {
      light.castShadow = true;
      light.shadow.bias = element.shadowBias;
      light.shadow.mapSize.width = element.shadowMapSizeWidth;
      light.shadow.mapSize.height = element.shadowMapSizeHeight;
    }

    scene.add(light);
  });
};
