import * as THREE from 'three';
import { AxesHelper, Clock } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { setLights } from './lighting';

declare let window: any;
const axisHelperEnabled = false;

export const setupScene = (canvas: HTMLCanvasElement) => {
  // Scene
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xcfcfcf);

  // Camera
  const camera = new THREE.PerspectiveCamera(75, canvas.clientWidth / canvas.clientHeight);

  // Renderer
  const renderer = new THREE.WebGLRenderer({
    antialias: true
  });

  // Render function to update the scene
  const render = () => renderer.render(scene, camera);

  // Mouse Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.03;
  controls.maxPolarAngle = Math.PI * 0.49;
  controls.enablePan = false;
  controls.minDistance = 3;
  controls.maxDistance = 10;

  controls.rotateSpeed = 0.3;

  // XYZ Axes Helper
  if (axisHelperEnabled) {
    const axesHelper = new AxesHelper(3);
    scene.add(axesHelper);
  }

  const clock = new Clock();

  // Auto resize canvas for responsiveness
  const resize = () => {
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    renderer.domElement.height = canvas.clientHeight;
    renderer.domElement.width = canvas.clientWidth;
    renderer.domElement.style.height = canvas.clientHeight + 'px';
    renderer.domElement.style.width = canvas.clientWidth + 'px';

    render();
  };

  // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.toneMappingExposure = 1.5;
  renderer.outputEncoding = THREE.sRGBEncoding;

  canvas.appendChild(renderer.domElement);

  // Default Camera Position
  camera.position.z = 4;
  camera.position.x = 4;
  camera.position.y = 3;

  scene.add(camera);

  setLights(scene);

  window.addEventListener('resize', resize);

  resize();

  window.scene = scene;
  window.camera = camera;
  window.renderer = renderer;
  window.render = render;

  return {
    scene,
    camera,
    renderer,
    controls,
    clock,
    render
  };
};
