import { RouteConfig } from 'vue-router/types/router';
import Error from '../views/Error/Index.vue';

export default [
  {
    path: '/error/:errorCode',
    name: 'Error',
    component: Error,
    props: true,
    meta: {
      noAuthRequired: true
    }
  }
] as Array<RouteConfig>;
